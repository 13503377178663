import React from "react";
import { Button, ExternalLink, Form, H1, Input, Modal, P } from "../components";
import { emailUrl, instagramUrl } from "../config";

export const Contact: React.FC = () => (
  <Modal>
    <H1 css={{ marginBottom: "20px" }}>Stay Rapt</H1>
    <Form
      action="https://world.us1.list-manage.com/subscribe/post?u=fba3ee51670c601d922237e24&amp;id=1046cf0689"
      target="_blank"
      method="post"
      noValidate
      css={{ marginBottom: "60px" }}
    >
      <Input
        type="email"
        name="EMAIL"
        id="email"
        placeholder="Your email address"
      />
      <Button type="submit">Sign up</Button>
    </Form>

    <P css={{ marginBottom: "30px", desktop: { display: "none" } }}>
      Follow us on Instagram at{" "}
      <ExternalLink href={instagramUrl}>@rapt.world</ExternalLink>
    </P>

    <P>
      To get in touch with us, email{" "}
      <ExternalLink href={emailUrl} css={{ display: "inline" }}>
        hello@rapt.world
      </ExternalLink>
    </P>
  </Modal>
);
