import { createStyled } from "@stitches/react";

export const { styled, css } = createStyled({
  tokens: {
    colors: {
      $black: "#000",
      $white: "#fff",
      $muted: "#C5C1BC",
    },
    fontSizes: {
      $14: "14px",
      $15: "15px",
      $18: "18px",
      $25: "25px",
      $30: "30px",
    },
    lineHeights: {
      $24: "24px",
      $28: "28px",
      $30: "30px",
    },
    fonts: {
      $america:
        '"GT America", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
    },
    fontWeights: {
      $regular: "300",
    },
    zIndices: {
      $header: "100",
      $modal: "999",
    },
    space: {
      $header: "93px",
    },
  },
  breakpoints: {
    desktop: (rule) => `@media (min-width: 700px) { ${rule} }`,
  },
  utils: {
    marginHorizontal: (config) => (value) => ({
      marginLeft: value,
      marginRight: value,
    }),
    marginVertical: (config) => (value) => ({
      marginTop: value,
      marginBottom: value,
    }),
  },
});
