import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import {
  About,
  Contact,
  Home,
  Podcast,
  Productions,
} from "./pages";

export const App: React.FC = () => (
  <BrowserRouter>
    <Switch>
      <Route path="/about" exact>
        <About />
      </Route>

      <Route path="/contact" exact>
        <Contact />
      </Route>

      <Route path="/podcast" exact>
        <Podcast />
      </Route>

      <Route path="/productions" exact>
        <Productions />
      </Route>
    </Switch>
    <Home />
  </BrowserRouter>
);
