import React from "react";
import { Link } from "react-router-dom";
import * as config from "../../config";
import { styled } from "../../stitches.config";

const Container = styled("header", {
  position: "fixed",
  top: 0,
  left: 0,
  width: "100vw",
  height: "$header",
  zIndex: "$header",
  backgroundColor: "$black",
  display: "grid",
  gridTemplateColumns: "repeat(3, 1fr)",
  desktop: {
    gridTemplateColumns: "repeat(4, 1fr)",
  },
});

// fixme: type signature
const headerLinkStyles: any = {
  paddingTop: "33px",
  paddingBottom: "42px",
  color: "$muted",
  transition: "100ms ease color",
  textAlign: "center",
  fontSize: "$14",
  lineHeight: "$27",
  textDecoration: "none",
  ":hover": {
    color: "$white",
  },
};

const HeaderLink = styled(Link, headerLinkStyles);
const HeaderExternalLink = styled("a", headerLinkStyles);

export const Header: React.FC = () => (
  <Container>
    <HeaderLink to="/productions">Productions</HeaderLink>
    <HeaderLink to="/podcast">Podcast</HeaderLink>
    <HeaderExternalLink
      href={config.instagramUrl}
      css={{ display: "none", desktop: { display: "initial" } }}
    >
      Instagram
    </HeaderExternalLink>
    <HeaderLink to="/contact">Keep in touch</HeaderLink>
  </Container>
);
