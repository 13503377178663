import React from "react";
import { H3, Link, Modal, P } from "../components";

export const About: React.FC = () => (
  <Modal>
    <H3 css={{ marginBottom: "23px" }}>About Rapt</H3>
    <P css={{ marginBottom: "23px" }}>
      Rapt is a production company born of a new era. Dedicated to telling
      stories in compelling and unprecedented ways, Rapt prioritises character
      based stories with a distinct visual flare. Founded by and made for
      artists, Rapt is led by Maisie Williams and BAFTA Cymru winner Lowri
      Roberts.
    </P>

    <H3 css={{ marginBottom: "23px" }}>Coming soon</H3>

    <P>
      We’ve got film, TV and documentaries in the works. Can’t wait? Get cosy
      with our <Link to="/podcast">podcast</Link>.
    </P>
  </Modal>
);
