import React from "react";
import { Modal } from "../components";
import { styled } from "../stitches.config";

const ProductionLink = styled.a({
  fontSize: "$30",
  fontWeight: "$regular",
  lineHeight: "$30",
  color: "$white",
  textDecoration: "none",
  marginBottom: "60px",
  display: "block",
  width: "100%",
  ":last-child": {
    marginBottom: "0",
  },
});

const ProductionSubtitle = styled.div({
  fontSize: "$25",
  marginTop: "16px",
});

const Production: React.FC<{ title: string; subtitle: string; url?: string }> =
  ({ title, subtitle, url }) => (
    <ProductionLink href={url} target={url ? "_blank" : undefined}>
      {title}
      <ProductionSubtitle>{subtitle}</ProductionSubtitle>
    </ProductionLink>
  );

export const Productions: React.FC = () => (
  <Modal>
    <Production
      title="The Zangwills"
      subtitle="Judas On The Dancefloor"
      url="https://www.youtube.com/watch?v=PKxkY37ZUGw"
    />
    <Production
      title="Guilia"
      subtitle="BYKIFU"
      url="https://www.youtube.com/watch?v=VwNndj29EvA"
    />
    <Production
      title="LYR"
      subtitle="Winter Solstice"
      url="https://www.youtube.com/watch?v=fK-xy_1C_o4"
    />
    <Production title="Light Before Sunrise" subtitle="Coming soon" />
  </Modal>
);
