import { Link as ReactRouterLink } from "react-router-dom";
import { styled } from "../../stitches.config";

export const Link = styled(ReactRouterLink, {
  fontSize: "$15",
  lineHeight: "$24",
  color: "$white",
  textDecoration: "underline",
  fontFamily: "$america",
  fontWeight: "$regular",
});

export const ExternalLink = styled.a({
  fontSize: "$15",
  lineHeight: "$24",
  color: "$white",
  textDecoration: "underline",
  fontFamily: "$america",
  fontWeight: "$regular",
});
