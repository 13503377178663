import React from "react";

export const Cross: React.FC = () => (
  <svg viewBox="0 0 20 20" width="20" height="20">
    <path
      d="M18.38,2.62l-1.06-1.06L9.95,8.93L2.59,1.56L1.53,2.62l7.37,7.37l-7.37,7.37l1.06,1.06l7.37-7.37 l7.36,7.37l1.06-1.06l-7.37-7.37L18.38,2.62z"
      fill="#fff"
    />
  </svg>
);
