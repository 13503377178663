import React from "react";
import { Link } from "react-router-dom";
import { styled } from "../../stitches.config";
import { Chevron } from "../elements";

const Container = styled(Link, {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  textDecoration: "none",
  marginBottom: "105px",
  marginTop: "105px",
});

const LinkText = styled("div", {
  fontSize: "$18",
  lineHeight: "$28",
  color: "$white",
  fontWeight: "$regular",
  marginTop: "25px",
});

export const AboutLauncher: React.FC = () => (
  <Container to="/about">
    <Chevron direction="up" />
    <LinkText>About Rapt</LinkText>
  </Container>
);
