import React from "react";

export interface ChevronProps {
  direction: "up" | "down";
}
export const Chevron: React.FC<ChevronProps> = ({ direction }) => (
  <svg
    viewBox="0 0 20 20"
    width="20"
    height="20"
    style={{
      transform: direction === "down" ? "rotate(180deg)" : undefined,
    }}
  >
    <path
      d="M2.64,14.74L10,7.38l7.36,7.37l0,0l1.06-1.06l-7.37-7.37L10,5.26L8.94,6.32l-7.37,7.37L2.64,14.74z"
      fill="#fff"
    />
  </svg>
);
