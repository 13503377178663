import React, { useEffect, useState } from "react";
import {
  AboutLauncher,
  Box,
  H2,
  H3,
  Header,
  LandingVideo,
  P,
} from "../components";

export const Home: React.FC = () => {
  const [videoEnded, setVideoEnded] = useState(false);
  const [scrollUnlocked, setScrollUnlocked] = useState(false);

  useEffect(() => {
    if (videoEnded && !scrollUnlocked) {
      const timeoutHandle = setTimeout(() => {
        setScrollUnlocked(true);
      }, 500);

      return () => clearTimeout(timeoutHandle);
    }
  }, [videoEnded, scrollUnlocked]);

  return (
    <>
      <Header />
      <Box
        className={videoEnded ? undefined : "fill-available"}
        css={{
          overflowY: videoEnded && scrollUnlocked ? "initial" : "hidden",
          overflowX: "hidden",
        }}
      >
        <LandingVideo
          disappeared={videoEnded}
          onEnd={() => {
            setVideoEnded(true);
          }}
        />
        <Box
          css={{
            paddingTop: "150px",
            textAlign: "center",
            margin: "0 15px",
            opacity: videoEnded ? 1 : 0,
            visibility: videoEnded ? "visible" : "hidden",
            height: videoEnded ? "initial" : 0,
            overflowY: videoEnded ? "initial" : "hidden",
            transform: videoEnded ? "translate(0, 0)" : "translate(0, 100vh)",
            transition: "0.5s ease all",
            desktop: { maxWidth: "700px", marginHorizontal: "auto" },
            minHeight: videoEnded ? "100vh" : 0,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <H2 css={{ marginBottom: "50px" }}>The Rapt way</H2>

          <H3 css={{ marginBottom: "19px" }}>We’re relentlessly curious</H3>
          <P css={{ marginBottom: "44px" }}>
            We’re fascinated by people’s minds: how they interact with others,
            what they long for, what drives them. We love to get lost in their
            heads, trying to understand their choices – even if we would have
            done things differently.
          </P>

          <H3 css={{ marginBottom: "19px" }}>We amplify a range of voices</H3>
          <P css={{ marginBottom: "44px" }}>
            We’re driven to help different people express how they see the
            world, like writers, editors and everyone in between. We’re always
            willing to have someone change our mind. In fact, we love it.
          </P>

          <H3 css={{ marginBottom: "19px" }}>
            We create unforgettable characters
          </H3>
          <P>
            We’re obsessed with crafting rich, magnetic characters that draw you
            in and refuse to let go. Our characters are the project – from the
            protagonists to the people they meet, across the story.
          </P>
          <AboutLauncher />
        </Box>
      </Box>
    </>
  );
};
