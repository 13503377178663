import { styled } from "../../stitches.config";

export const Button = styled.button({
  fontFamily: "$america",
  backgroundColor: "transparent",
  color: "$white",
  fontSize: "$18",
  border: "none",
  width: "100%",
  cursor: "pointer",
  padding: "14px",
});
