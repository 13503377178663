import { styled } from "../../stitches.config";

export const BigExternalLink = styled.a({
  fontSize: "$30",
  fontWeight: "$regular",
  lineHeight: "$30",
  color: "$white",
  textDecoration: "none",
  marginBottom: "38px",
  ":last-child": {
    marginBottom: "0",
  },
});
