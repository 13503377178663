import { styled } from "../../stitches.config";

export const Input = styled.input({
  backgroundColor: "transparent",
  border: "none",
  borderBottom: "1px $muted solid",
  color: "$white",
  padding: "14px",
  width: "100%",
  display: "block",
  fontFamily: "$america",
  outline: "none",
  fontSize: "$18",
  textAlign: "center",
  marginBottom: "14px",

  "::placeholder": {
    color: "$muted",
  },
});
