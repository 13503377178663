import React from "react";
import { styled } from "../../stitches.config";
import { P } from "../elements";

const BigP = styled(P, {
  fontSize: "$25",
  lineHeight: "$30",
});

export const ComingSoon: React.FC = () => <BigP>Coming soon!</BigP>;
