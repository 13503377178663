import React, { useRef } from "react";
import { styled } from "../../stitches.config";
import { Chevron, H1 } from "../elements";
import landingPoster from "../../assets/landingPoster.jpg";
import landingVideo from "../../assets/landingVideo.mp4";

const Container = styled("div", {
  position: "absolute",
  top: 0,
  left: 0,
  width: "100vw",
  transition: "0.5s ease all",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
});

const Video = styled("video", {
  display: "block",
  height: "min(100vh, 100vw)",
  width: "min(100vh, 100vw)",
});

const Strapline = styled(H1, {
  position: "absolute",
  bottom: "100px",
  left: "12px",
  right: "12px",
  textAlign: "center",
});

const ContinueLink = styled("a", {
  position: "absolute",
  top: 0,
  left: 0,
  bottom: 0,
  right: 0,
  textAlign: "center",
  textDecoration: "none",
  display: "flex",
  alignItems: "flex-end",
  justifyContent: "center",
  paddingBottom: "36px",
  cursor: "pointer",
});

export interface LandingVideoProps {
  onEnd: () => void;
  disappeared: boolean;
}

export const LandingVideo: React.FC<LandingVideoProps> = ({
  onEnd,
  disappeared,
}) => {
  const videoRef = useRef<HTMLVideoElement>(null);

  return (
    <Container
      className="fill-available"
      css={{
        transform: disappeared ? "translate(0, -100vh)" : "translate(0, 0)",
      }}
    >
      <Video
        src={landingVideo}
        poster={landingPoster}
        muted
        playsInline
        ref={videoRef}
        onCanPlayThrough={(event) => event.currentTarget.play()}
        onEnded={() => onEnd()}
        preload="auto"
      />

      <Strapline>Watch and listen. Expand your mind. Be Rapt.</Strapline>

      <ContinueLink
        onClick={(e) => {
          e.preventDefault();
          onEnd();
        }}
      >
        <Chevron direction="down" />
      </ContinueLink>
    </Container>
  );
};
