import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { css, styled } from "../../stitches.config";
import { Box, Cross } from "../elements";

const fadeIn = css.keyframes({
  from: { opacity: 0 },
  to: { opacity: 1 },
});

const Container = styled("div", {
  position: "fixed",
  top: 0,
  left: 0,
  width: "100vw",
  backgroundColor: "$black",
  zIndex: "$modal",
  padding: "130px 60px",
  animation: `${fadeIn} 350ms ease`,
  overflowY: "auto",
});

const CloseButton = styled(Link, {
  position: "absolute",
  top: "16px",
  right: "16px",
});

export const Modal: React.FC = ({ children }) => {
  useEffect(() => {
    document.body.style.overflowY = "hidden";

    return () => {
      document.body.style.overflowY = "initial";
    };
  });

  return (
    <Container className="fill-available">
      <CloseButton to="/">
        <Cross />
      </CloseButton>
      <Box
        css={{
          minHeight: "100%",
          maxWidth: "700px",
          marginHorizontal: "auto",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          textAlign: "center",
          justifyContent: "center",
        }}
      >
        {children}
      </Box>
    </Container>
  );
};
