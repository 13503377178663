import React from "react";
import { BigExternalLink, H1, Modal } from "../components";

export const Podcast: React.FC = () => (
  <Modal>
    <H1 css={{ marginBottom: "40px" }}>Frank Film Club with Maisie Williams</H1>
    <BigExternalLink
      href="https://podcasts.apple.com/gb/podcast/frank-film-club-with-maisie-williams/id1565563311"
      target="_blank"
    >
      Apple Podcasts
    </BigExternalLink>
    <BigExternalLink href="https://open.spotify.com/show/4ZpUr83bUP3JU5ggtNCd0z?si=a0ae0b2f89e842a6">
      Spotify
    </BigExternalLink>
  </Modal>
);
